(function (global) {
  const systemPrototype = global.System.constructor.prototype;

  const asyncImportPrefix = "systemjs://m/";
  const getUrl = (id) => asyncImportPrefix + id;
  systemPrototype.appendImportMap = function (packages) {
    const loader = this;
    loader.appendedMap = loader.appendedMap || {};
    loader.appendedMap = {
      ...loader.appendedMap,
      ...packages,
    };

    // creating import map from package
    const packageNames = Object.keys(packages);
    const sjsMapElm = document.createElement("script");

    // meet import map rules
    sjsMapElm.type = "systemjs-importmap";

    const packageMap = {};
    for (const name of packageNames) {
      // Use it directly if the package already provide an url, other assume that is a internal url with import syntax
      packageMap[name] = typeof packages[name] === "string" ? packages[name] : getUrl(name);
    }

    sjsMapElm.innerText = JSON.stringify({
      imports: packageMap,
    });
    document.head.appendChild(sjsMapElm);
    return loader.prepareImport(true);
  };
  const existingHook = systemPrototype.instantiate;
  systemPrototype.instantiate = function (url, parentUrl) {
      const loader = this;

    // use original loader
    if (url.indexOf(asyncImportPrefix) !== 0) {
      return existingHook.apply(loader, arguments);
    }
    const packageName = url.substr(asyncImportPrefix.length);
    return new Promise( async (resolve, reject) => {
        try {

          const importer = loader.appendedMap[packageName];

          const result = await importer();
          loader.register([], (_export, _context) => {
            _export(result);
            return {
              execute: () => {
              }
            }
          })
          const register = loader.getRegister();
          resolve(register);

        } catch (error) {
          reject(error);
        }
    });
  };
  // eslint-disable-next-line
})(typeof self !== "undefined" ? self : global);